import React, { FunctionComponent, useState, useEffect } from "react";
import { Card, Typography, Skeleton, Badge } from "antd";
import { ApiPosition, ApiPrice } from "../models";
import { instance, getColor } from "../utils";
import { RouteComponentProps } from "react-router-dom";

interface PositionProps {
  position: ApiPosition
}

const Position:FunctionComponent<PositionProps & RouteComponentProps> = (props) => {
  const [price, setPrice] = useState<ApiPrice | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const prices = await instance.get(`/v1/prices/${props.position.broker}/${props.position.action}/${props.position.currency}`)
      setPrice(prices.data.price)
    }
    fetchData()
  }, [props.position])

  let win = 0
  if (price) {
    win = ((price.lastSale ? price.lastSale.price : price.bid) - props.position.openPrice)
  }

  return <Card
      hoverable
      style={{
        maxWidth: 300,
        backgroundColor: getColor(props.position.traderId) + '20',
        borderColor: getColor(props.position.traderId) + '60',
        fontFeatureSettings: "'ss01' 1, 'tnum' 1"
      }}
      onClick={() => props.history.push(`/positions/${props.position.id}`)}
    >
    <Typography.Text><b>{props.position.quantity} × {props.position.action} @{props.position.openPrice}</b></Typography.Text><br />
    {
      price ? <>
        <Badge count={win.toFixed(4)} style={{ backgroundColor: win > 0 ? '#52c41a' : '' }} /> on {price.lastSale ? price.lastSale.price : price.bid}
      </> : <Skeleton />
    }<br />
    <Typography.Text><i>{props.position.traderType} on {props.position.broker}</i></Typography.Text><br />
  </Card>
}

export { Position }