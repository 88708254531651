import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApiAsset, ApiBroker, ApiPosition } from '../models'
import { instance } from "../utils"
import { List, Space } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

type BrokerParams = { id: string }

interface BrokerPageProps {}

export const BrokerPage: FunctionComponent<BrokerPageProps & RouteComponentProps<BrokerParams>> = ({ match }) => {
  const [broker, setBroker] = useState<ApiBroker | null>(null)
  const [assets, setAssets] = useState<ApiAsset[]>([])
  const [positions, setPositions] = useState<ApiPosition[]>([])
  // TODO: add accounts

  const fetchBroker = async () => {
    const broker = await instance.get(`/v1/brokers/broker/${match.params.id}`)
    setBroker(broker.data.broker)
  }
  const fetchAssets = async () => {
    const assets = await instance.get(`/v1/brokers/broker/${match.params.id}/assets`)
    setAssets(assets.data.assets)
  }
  const fetchPositions = async () => {
    const positions = await instance.get(`/v1/brokers/broker/${match.params.id}/positions`)
    setPositions(positions.data.positions)
  }

  useEffect(() => {
    fetchBroker()
    fetchAssets()
    fetchPositions()
  }, [match.params.id])

  return <>
    <h2>Broker {match.params.id}</h2>
    <div>
      <h3>Data</h3>
      <pre>{JSON.stringify(broker, null, 2)}</pre>
    </div>
    <div>
      <h3>Positions</h3>
      <List>
        {
          positions.map(position => <List.Item>
            <List.Item.Meta title="Action" description={position.action} />
            <List.Item.Meta title="Quantity" description={position.quantity} />
          </List.Item>)
        }
      </List>
    </div>
    <div>
      <h3>Assets</h3>
      <List>
        {
          assets.map(asset => <List.Item>
            <List.Item.Meta title="Symbol" description={asset.symbol} />
            { asset.meta && Object.keys(asset.meta).map(key => <List.Item.Meta title={key} description={asset.meta![key]} />)}
          </List.Item>)
        }
      </List>
    </div>
  </>
}