import { ApiPosition } from "../models"
import { FunctionComponent, useEffect, useState } from "react"
import React from "react"
import { Col, Row } from "antd"
import { Position } from "../components/position"
import { instance } from "../utils"
import { RouteComponentProps } from "react-router-dom"

interface PositionsPageProps {
}

const PositionsPage: FunctionComponent<PositionsPageProps & RouteComponentProps> = (props) => {
  const [since, setSince] = useState<'day'>('day')

  const [positions, setPositions] = useState<ApiPosition[]>([])
  const fetchPositions = async () => {
    const positions = await instance.get('/v1/positions/current')
    setPositions(positions.data.positions)
  }

  useEffect(() => {
    fetchPositions()
  }, [])
  
  return <>
    <h2>Positions</h2>
    <Row gutter={[16, 16]}>
      {
        positions.length === 0 ? <p>No positions</p> : null
      }
      {
        positions?.map(position => <Col><Position position={position} {...props} /></Col>)
      }
    </Row>
  </>
}

export { PositionsPage }