import { ApiAccount, ApiBroker, ApiPosition } from "../models"
import { FunctionComponent, useEffect, useState } from "react"
import React from "react"
import { Col, Row, Space } from "antd"
import { Position } from "../components/position"
import { instance } from "../utils"
import { Broker } from "../components/broker"

interface BrokersPageProps {
}

const BrokersPage: FunctionComponent<BrokersPageProps> = (props) => {
  const [since, setSince] = useState<'day'>('day')

  const [brokers, setBrokers] = useState<ApiBroker[]>([])
  const [accounts, setAccounts] = useState<ApiAccount[]>([])

  const fetchBrokers = async () => {
    const brokers = await instance.get('/v1/brokers')
    setBrokers(brokers.data.brokers)
  }

  useEffect(() => {
    fetchBrokers()
  }, [])
  
  return <>
    <h2>Brokers</h2>
    <Space>
      {
        brokers.length === 0 ? <p>No broker</p> : null
      }
      {
        brokers?.map(broker => <Broker broker={broker} />)
      }
    </Space>
  </>
}

export { BrokersPage }