import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, Typography, Button, Modal, notification, Spin, Alert } from "antd";
import { ApiBroker, ApiAccount } from "../models";
import { ModalFuncProps } from "antd/lib/modal";
import { instance } from "../utils";
import { useHistory } from "react-router-dom";

interface BrokerProps {
  broker: ApiBroker
}

enum AccountsState {
  Loaded,
  Loading,
  Failed,
}

const Broker:FunctionComponent<BrokerProps> = (props) => {
  const history = useHistory()
  const [modal, contextHolder] = Modal.useModal();

  const [accountsState, setAccountsState] = useState<AccountsState>(AccountsState.Loading)
  const [accounts, setAccounts] = useState<ApiAccount[]>([])

  const fetchAccounts = async () => {
    setAccountsState(AccountsState.Loading)
    try {
      const accounts = await instance.get(`/v1/brokers/broker/${props.broker.name}/accounts`)
      setAccounts(accounts.data.accounts)
      setAccountsState(AccountsState.Loaded)
    } catch (e) {
      setAccountsState(AccountsState.Failed)
    }
  }

  useEffect(() => {
    fetchAccounts()
  }, [props.broker.name])

  const modalSwitchState: ModalFuncProps = {
    title: 'Are you sure?',
    content: 'No broker will be able to order or subscribe, you must be sure to be outside market opening hours.',
    onOk: async () => {
      try {
        await instance.put(`/v1/brokers/broker/${props.broker.name}`, {
          broker: {
            state: props.broker.state === 'started' ? 'paused' : 'started'
          }
        })
        notification.success({
          message: 'Broker modified',
          description: 'State has been modified'
        })
      } catch (e) {
        console.error(e)
        notification.error({
          message: 'Error',
          description: e.toString()
        })
      }
    }
  }

  return <Card hoverable style={{
      width: 300,
      borderColor: props.broker.state === 'paused' ? '#e74c3cf0' : undefined,
      backgroundColor: props.broker.state === 'paused' ? '#e74c3c60' : undefined,
      animation: props.broker.state === 'paused' ? 'pulse 2s infinite': undefined
    }} onClick={() => history.push(`/brokers/${props.broker.name}`)}>
    <Typography.Text><b>{props.broker.name}</b></Typography.Text><br />
    { accountsState === AccountsState.Loading ? <Spin /> : null}
    { accountsState === AccountsState.Failed ? <Alert message="Cannot get accounts" /> : null}
    <Typography.Text>
      <ul>
      {
        accounts?.map(account => {
          return <li style={{ fontFeatureSettings: "'ss01' 1, 'tnum' 1" }}>{account.total.toFixed(4)}/{account.free.toFixed(4)} {account.currency}</li>
        })
      }
      </ul>
    </Typography.Text>
    <Typography.Text><i>{props.broker.state}</i></Typography.Text><br /><br />
    <Button onClick={() => modal.confirm(modalSwitchState)}>Switch state</Button>
    {contextHolder}
  </Card>
}

export { Broker }