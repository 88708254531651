import React, { FunctionComponent, useEffect } from "react";
import QRCode from 'qrcode.react'
import { server, token } from "../utils";

const QrcodePage:FunctionComponent = () => {
  useEffect(() => {
    console.log(`florianhjautotrading://initialize?server=${server}&token=${token}`)
  })
  return <>
    <QRCode value={`florianhjautotrading://initialize?server=${server}&token=${token}`} />
  </>
}

export { QrcodePage }