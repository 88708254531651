import React, { FunctionComponent, useState, useEffect } from 'react';
import "antd/dist/antd.css"
import "./App.css"
import { BrowserRouter as Router, Route, Switch, RouteComponentProps, Link } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import { Statuses } from './pages/statuses';
import { CreateTrader } from './pages/createTrader';
import { Layout, Typography, Space, Breadcrumb, Menu } from 'antd';
import { TraderPage } from './pages/trader';
import { EditTrader } from './pages/editTrader';
import { TradersPage } from './pages/traders';
import { QrcodePage } from './pages/qrcode';
import { AddPositionTrader } from './pages/addPositionTrader';
import { PositionsPage } from './pages/positions';
import { BrokersPage } from './pages/brokers';
import { PositionPage } from './pages/position';
import { BrokerPage } from './pages/broker';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles: any) {
  return {
    opacity: styles.opacity,
    transform: `translateY(${styles.offset}%)`,
  };
}

const translationTransition = {
  atEnter: {
    offset: -4,
    opacity: 0,
  },
  atLeave: {
    offset: 4,
    opacity: 0,
  },
  atActive: {
    offset: 0,
    opacity: 1,
  },
};

const opacityTransition = {
  atEnter: {
    opacity: 0,
  },
  atLeave: {
    opacity: 0,
  },
  atActive: {
    opacity: 1,
  },
};

const App:FunctionComponent<RouteComponentProps<{}>> = (props) => {
  const splitPath = (path: string) => {
    const splitPath = path.split('/')
    let endPaths = [splitPath[0]]
    if (splitPath.length >= 2) {
      for (let i = 1; i <= splitPath.length - 1; i++) {
        endPaths.push(endPaths[i - 1] + '/' + splitPath[i])
      }
    }

    return endPaths
  }

  const [splitedPath, setSplitedPath] = useState(splitPath(props.location.pathname))

  useEffect(() => {
    setSplitedPath(splitPath(props.location.pathname))
  }, [props.location.pathname])

  return <Layout className="layout" >
    <Layout.Header>
      <span style={{
        float: 'left',
        color: 'white',
        marginRight: '2em',
        paddingRight: '1em',
        fontWeight: 'bold',
      }}>
        <img src='/favicon.svg' width='30px' height='30px' style={{ marginRight: '1em', borderRadius: '2px' }} />
        STONKS
      </span>
      <Menu theme="dark" mode="horizontal" selectedKeys={splitedPath}>
        <Menu.Item key="/"><Link to="/">Home</Link></Menu.Item>
        <Menu.Item key="/brokers"><Link to="/brokers">Brokers</Link></Menu.Item>
        <Menu.Item key="/traders"><Link to="/traders">Traders</Link></Menu.Item>
        <Menu.Item key="/positions"><Link to="/positions">Positions</Link></Menu.Item>
      </Menu>
      
    </Layout.Header>
    <Layout.Content style={{ padding: '0 50px', height: '100%' }}>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-content">
        <AnimatedSwitch
            atEnter={opacityTransition.atEnter}
            atLeave={opacityTransition.atLeave}
            atActive={opacityTransition.atActive}
            mapStyles={mapStyles}
            className="route-wrapper"
          >
          <Route exact path="/" component={Statuses} />
          <Route exact path="/qrcode" component={QrcodePage} />
          <Route exact path="/brokers" component={BrokersPage} />
          <Route exact path="/brokers/:id" component={BrokerPage} />
          <Route exact path="/positions" component={PositionsPage} />
          <Route exact path="/positions/:id" component={PositionPage} />
          <Route exact path="/traders" component={TradersPage} />
          <Route exact path="/traders/create" component={CreateTrader} />
          <Route exact path="/traders/trader/:id" component={TraderPage} />
          <Route exact path="/traders/trader/:id/edit" component={EditTrader} />
          <Route exact path="/traders/trader/:id/addPosition" component={AddPositionTrader} />
        </AnimatedSwitch>
      </div>
    </Layout.Content>
    <Layout.Footer style={{ textAlign: 'center' }}></Layout.Footer>
  </Layout>
}

export { App }