import React, { FunctionComponent, useState, useEffect } from "react"
import { ApiTraderType, ApiBroker, ApiTrader } from "../models"
import { instance } from "../utils"
import { Form, Button, Select, Input, InputNumber, notification } from "antd";
import { Store } from "antd/lib/form/interface";
import TextArea from "antd/lib/input/TextArea";
import { RouteComponentProps } from "react-router-dom";

type TraderParams = { id: string }

interface TraderProps {
  trader: ApiTrader
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const EditTrader:FunctionComponent<TraderProps & RouteComponentProps<TraderParams>> = (props) => {
  const [form] = Form.useForm();
  const [trader, setTrader] = useState<ApiTrader | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      const trader = await instance.get(`/v1/traders/trader/${props.match.params.id}`)
      trader.data.trader.meta = JSON.stringify(trader.data.trader.meta, null, 2)
      setTrader(trader.data.trader)
    }
    fetchData()
  }, [props.match.params.id])

  const onFinish = async (values: Store) => {
    values.meta = JSON.parse(values.meta)

    try {
      const res = await instance.put(`/v1/traders/trader/${props.match.params.id}`, {
        trader: {
          quantity: parseFloat(values.quantity),
          maxPositions: parseInt(values.maxPositions),
          minWin: parseFloat(values.minWin),
          offsetSeconds: parseInt(values.offsetSeconds),
          meta: values.meta
        }
      })
      if (res.data.success) {
        notification.success({
          message: 'Trader modified',
          description: 'The trader will use theses values'
        })
      }
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Error',
        description: e.toString()
      })
    }
  }

  if (!trader) {
    return <p>Loading...</p>
  }

  return <>
    <h2>Edit trader</h2>
    <p>Edit an existant trader. You can edit theses fields only, if you want to edit other fields, you must delete and recreate another trader.<br />
      Current positions will be kept and modifications will be used for the next run.</p><br />
    <Form {...layout} form={form} initialValues={trader} onFinish={onFinish}>
      <Form.Item name="quantity" label="Quantity" extra="Block of buy sell (bigger number for cheap actions, fraction for expensive)">
        <InputNumber placeholder="1"  />
      </Form.Item>
      <Form.Item name="maxPositions" label="Max positions" extra="Number of positions at the same time (nb actions = quantity . maxPositions)">
        <InputNumber placeholder="8"  />
      </Form.Item>
      <Form.Item name="minWin" label="Minimum to win/action" extra="Sell if the win/action is >= minWin">
        <InputNumber placeholder="0.02"  />
      </Form.Item>
      <Form.Item name="offsetSeconds" label="Start offset" extra="This trader will start x seconds after the engine">
        <InputNumber placeholder="60"  />
      </Form.Item>
      <Form.Item name="meta" label="Trader meta" extra="JSON, depends of the trader type, check source">
          <TextArea placeholder="{}" />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </>
}

export { EditTrader }