import React, { FunctionComponent } from "react";
import { Card, Typography, Button, Modal, notification } from "antd";
import { ApiTrader } from "../../models";
import { ModalFuncProps } from "antd/lib/modal";
import { instance, getColor } from "../../utils";
import { TraderMeta } from "./meta";
import { RouteComponentProps } from "react-router-dom";

interface TraderProps {
  trader: ApiTrader
}

const Trader:FunctionComponent<TraderProps & RouteComponentProps> = (props) => {
  return <Card
    hoverable
    style={{
      maxWidth: 400,
      backgroundColor: getColor(props.trader.id) + '20',
      borderColor: getColor(props.trader.id) + '60',
      fontFeatureSettings: "'ss01' 1, 'tnum' 1"
    }}
    onClick={() => props.history.push(`/traders/trader/${props.trader.id}`)}
    >
    <Typography.Text><b>{props.trader.quantity} × {props.trader.action} @{props.trader.minWin} - {props.trader.maxPositions} max</b></Typography.Text><br />
    <Typography.Text>{props.trader.type} on {props.trader.broker}</Typography.Text><br /><br />
    <Typography.Text>Last run on <b>{new Date(props.trader.lastRun).toLocaleString()}</b><br /></Typography.Text>
    <TraderMeta trader={props.trader} />
  </Card>
}

export { Trader }